import {
	ColumnDef,
	OnChangeFn,
	RowModel,
	RowSelectionState,
	SortingState
} from "@tanstack/react-table";
import {
	IcoArrowUp,
	IcoCheckRounded,
	IcoPencil,
	IcoReverterHorizontal
} from "assets/icons";
import colors from "colors";
import { Button, ColoredTag, Pagination, Table } from "components";
import { Clause } from "models/covenant/Clause";
import { useCallback, useEffect, useMemo, useState } from "react";

import ModalCancelConfirm from "components/ModalCancelConfirm";
import ToastContent from "components/ToastContent";
import { ErrorHelper } from "helpers";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { ClauseService } from "services/clauses";
import { ClauseCategory } from "store/features/categories/slice";
import ChangeCategoriesDrawer from "./ChangeCategoriesDrawer";
import "./styles.scss";
import { getStatusType } from "./utils/getStatusType";

type CovenantAnalysisTableProps = {
	covenantId: string;
	content: Clause[];
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	pageSize: number;
	reloadData: () => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
	statusFilter: string;
};

export default function CovenantAnalysisTable({
	covenantId,
	content,
	currentPage,
	totalPages,
	onPageChange,
	pageSize,
	reloadData,
	sorting,
	setSorting,
	statusFilter
}: CovenantAnalysisTableProps) {
	const clauseService = new ClauseService();
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
	const [isScrolledDown, setIsScrolledDown] = useState(false);
	const [selectionRowModel, setSelectedRowModel] = useState<RowModel<any>>();
	const [selectRow, setSelectedRow] = useState<string>();
	const [isModalEditClausesOpen, setIsModalEditClausesOpen] = useState(false);

	const [isModalApproveClausesOpen, setIsModalApproveClausesOpen] =
		useState(false);
	const [isModalChangeToOpenClausesOpen, setIsModalChangeToOpenClausesOpen] =
		useState(false);
	const [isLoadingApproveClauses, setIsLoadingApproveClauses] = useState(false);

	const changedCategories = useSelector(
		(state: any) => state.categories.categories
	);

	const columns: ColumnDef<Clause>[] = [
		{
			id: "suggestedCategory",
			accessorKey: "suggestedCategory.name",
			header: () => <span>Categoria Sugerida</span>,
			cell: (row) => {
				return (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				);
			},
			meta: {
				columnClass: "clause-category-column"
			}
		},
		{
			id: "teams",
			accessorKey: "teams",
			header: () => <span>Time Vinculado</span>,
			cell: (row) => {
				const teams = row.getValue() as { name: string }[];
				const teamsNames = teams
					?.map((team) => team.name)
					.sort()
					.join(", ");
				return (
					<div className="row-name">
						<p>{teamsNames}</p>
					</div>
				);
			},
			meta: {
				columnClass: "teams-column"
			}
		},
		{
			id: "assertiveness",
			accessorKey: "assertiveness",
			header: () => <span>Probabilidade de acerto</span>,
			cell: (row) => {
				const assertivenessPercentage = Math.floor(
					(row.getValue() as number) * 100
				);

				const hasCategorySuggested = row.row.original.suggested;

				const hasAssertiviness =
					hasCategorySuggested && assertivenessPercentage >= 60;

				return (
					<>
						{!hasAssertiviness && (
							<Tooltip
								anchorSelect=".categorie-assertiviness"
								place="top"
								className="z-50"
							>
								Categoria identificada <br />
								no documento enviado
							</Tooltip>
						)}
						<ColoredTag
							className="categorie-assertiviness"
							text={!hasAssertiviness ? "---" : `${assertivenessPercentage}%`}
							type={getStatusType(row.getValue() as number)}
						/>
					</>
				);
			},
			meta: {
				columnClass: "status-column"
			}
		}
	];

	const checkScrollTop = () => {
		const minPixelScrolled = 300;
		setIsScrolledDown(window.scrollY > minPixelScrolled);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScrollTop);
		return () => window.removeEventListener("scroll", checkScrollTop);
	}, []);

	const selectionLabel = () => {
		const numberRows = Object.keys(rowSelection)?.length;
		const labelName = numberRows > 1 ? "categorias" : "categoria";
		const labelSelected =
			numberRows > 1 ? "foram selecionadas" : "foi selecionada";

		return (
			<>
				<b>{`${numberRows} ${labelName} `}</b>
				{`${labelSelected} no total`}
			</>
		);
	};

	const handleApproveClauses = useCallback(
		async (clausesIds: string[]) => {
			setIsLoadingApproveClauses(true);

			const categoriesToSave = Array.isArray(changedCategories)
				? changedCategories?.filter((category: ClauseCategory) =>
						clausesIds.includes(category.clauseId)
				  )
				: [];

			return clauseService
				.approveClauseCategory(clausesIds)
				.then(async () => {
					if (categoriesToSave.length) {
						await Promise.all(
							categoriesToSave.map((category: ClauseCategory) =>
								clauseService.editClauseCategory(
									category.clauseId,
									category.categoryId
								)
							)
						);
					}
				})
				.then(() => {
					onPageChange(currentPage, pageSize);
				})
				.catch((error) => {
					if (!ErrorHelper.isAuthErrorDisplayed) {
						toast.error(
							<ToastContent
								type="error"
								title="Erro"
								subtitleError={error}
								onClose={() => toast.dismiss()}
							/>,
							{
								position: "top-center"
							}
						);
					}
				})
				.finally(() => {
					setIsModalApproveClausesOpen(false);
					setIsLoadingApproveClauses(false);
					reloadData();
				});
		},
		[reloadData]
	);

	const handleChangeClauseToOpen = useCallback(
		async (clausesIds: string[]) => {
			setIsLoadingApproveClauses(true);

			return clauseService
				.changeToOpenClauseCategory(clausesIds)
				.then(() => {
					onPageChange(currentPage, pageSize);
					reloadData();
				})
				.catch((error) => {
					if (!ErrorHelper.isAuthErrorDisplayed) {
						toast.error(
							<ToastContent
								type="error"
								title="Erro"
								subtitleError={error}
								onClose={() => toast.dismiss()}
							/>,
							{
								position: "top-center"
							}
						);
					}
				})
				.finally(() => {
					setIsModalChangeToOpenClausesOpen(false);
					setIsLoadingApproveClauses(false);
				});
		},
		[reloadData]
	);

	const selectionActions = useMemo(() => {
		const cancelSelection = () => setRowSelection({});
		const isOpen = statusFilter === "open";
		return (
			<>
				<Button styled="secondary" onClick={cancelSelection}>
					Cancelar
				</Button>
				<Button
					isLoading={isLoadingApproveClauses}
					styled="primary"
					onClick={() =>
						isOpen
							? setIsModalApproveClausesOpen(true)
							: setIsModalChangeToOpenClausesOpen(true)
					}
				>
					{isOpen ? (
						<>
							<IcoCheckRounded
								size="20"
								color={colors.neutral["high-pure-50"]}
							/>
							Aprovar categorias
						</>
					) : (
						<>
							<IcoReverterHorizontal
								size="20"
								color={colors.neutral["high-pure-50"]}
							/>
							Alterar para em aberto
						</>
					)}
				</Button>
			</>
		);
	}, [isLoadingApproveClauses, statusFilter]);

	const getSubRowComponent = (row: any, columnsSubRow: any[]) => {
		const subrowLength = columnsSubRow.length;

		return (
			<tr className="expanded-description-row" key={row.id}>
				<td className="select-column"> </td>
				<td className="subrow-column" colSpan={subrowLength}>
					<div className="content">
						<div className="subrow-content">{row.original.description}</div>
						<div className="subrow-buttons">
							{statusFilter === "open" ? (
								<>
									<Button
										size="small"
										styled="transparent"
										onClick={() => {
											setIsModalEditClausesOpen(true);
											setSelectedRow(row.original);
										}}
									>
										<IcoPencil size="23" />
										Alterar
									</Button>
									<Button
										isLoading={isLoadingApproveClauses}
										size="small"
										onClick={() =>
											handleApproveClauses([row.original.clauseId as string])
										}
									>
										<IcoCheckRounded
											size="23"
											color={colors.neutral["high-pure-50"]}
										/>
										Aprovar
									</Button>
								</>
							) : (
								<Button
									size="small"
									styled="transparent"
									onClick={() =>
										handleChangeClauseToOpen([row.original.clauseId as string])
									}
								>
									<IcoReverterHorizontal />
									Alterar para em aberto
								</Button>
							)}
						</div>
					</div>
				</td>
			</tr>
		);
	};

	return (
		<div className="covenant-analysis-table">
			<Table
				canExpand="end"
				columns={columns}
				data={content}
				getRowCanExpand={(row) => !!row.original.description}
				getSubRows={(row) =>
					row && typeof row === "object" && "id" in row
						? [
								{
									description: row.description,
									clauseId: row.id,
									status: row.status,
									clauseCategory: row.category,
									clauseNumber: row.clauseNumber,
									teams: row.teams
								}
						  ]
						: []
				}
				rowSelection={rowSelection}
				selectionActions={selectionActions}
				selectionLabel={selectionLabel}
				sorting={sorting}
				setRowSelection={setRowSelection}
				setSelectedRowModel={setSelectedRowModel}
				setSorting={setSorting}
				getSubRowComponent={getSubRowComponent}
				fixedRowsExpanded
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			<ModalCancelConfirm
				confirmLabel="Aprovar tudo"
				modalTitle="Deseja aprovar?"
				modalInfo="Com o objetivo de evitar problemas e impactos negativos no contrato, é importante que você leia com atenção cada uma das cláusulas selecionadas antes de valida-las."
				isOpen={isModalApproveClausesOpen}
				onClose={() => {
					setIsModalApproveClausesOpen(false);
				}}
				onConfirm={() =>
					handleApproveClauses(
						selectionRowModel
							? selectionRowModel?.rows.map((row) => row.original.id as string)
							: []
					)
				}
				toastSuccessTitle="Cláusulas aprovadas"
				toastSuccessMessage="As cláusulas foram aprovadas"
				kind="warning"
			/>
			<ModalCancelConfirm
				confirmLabel="Aprovar e mover"
				modalTitle="Deseja aprovar essa ação?"
				modalInfo="Ao salvar, esses itens serão movidos para outra sessão."
				isOpen={isModalChangeToOpenClausesOpen}
				onClose={() => {
					setIsModalChangeToOpenClausesOpen(false);
				}}
				onConfirm={() =>
					handleChangeClauseToOpen(
						selectionRowModel
							? selectionRowModel?.rows.map((row) => row.original.id as string)
							: []
					)
				}
				toastSuccessTitle="Cláusulas movidas"
				toastSuccessMessage="As cláusulas foram movidas"
				kind="warning"
			/>
			<ChangeCategoriesDrawer
				covenantId={covenantId}
				clause={selectRow}
				isOpen={isModalEditClausesOpen}
				onClose={() => setIsModalEditClausesOpen(false)}
				reloadData={reloadData}
			/>
			{isScrolledDown && (
				<Button
					onClick={scrollToTop}
					kind="icon"
					cssClass="scroll-to-top-button"
				>
					<IcoArrowUp color={colors.neutral["high-100"]} />
				</Button>
			)}
		</div>
	);
}
