import { Clause } from "models/covenant/Clause";
import { useMemo } from "react";

export const usePagination = (items: Clause[], page: number, size: number) => {
	return useMemo(() => {
		const start = (page - 1) * size;
		const paginatedItems = items.slice(start, start + size);
		return { paginatedItems, totalPages: Math.ceil(items.length / size) };
	}, [items, page, size]);
};
