import { Clause } from "models/covenant/Clause";
import { ClauseCreationEditionPropsRequest } from "models/covenant/types";
import { ObjectWithAnyValues } from "utils/types";
import { BaseServices } from "./baseServices";
import { BasePageResponse } from "./types";

export class ClauseService extends BaseServices<Clause> {
	constructor() {
		super(Clause);
	}

	override async retrieve(id: string): Promise<Clause> {
		return super.retrieve(`clauses/${id}`);
	}

	async approveClauseCategory(clausesIds: string[]) {
		return this.put("clauses/change-suggested", { clausesIds });
	}

	async changeToOpenClauseCategory(clausesIds: string[]) {
		return this.put("clauses/change-returned", { clausesIds });
	}

	async editClauseCategory(clausesId: string, categoryId?: string) {
		return this.put("clauses/change-validated", {
			clausesId,
			clauseCategoryId: categoryId
		});
	}

	async editClauseTeams(
		clause: ClauseCreationEditionPropsRequest,
		clauseId: string
	) {
		return this.put(`clauses/${clauseId}/draft`, clause);
	}

	async createClause(clause: ClauseCreationEditionPropsRequest) {
		return this.post("clauses", clause);
	}

	async editClause(
		clause: ClauseCreationEditionPropsRequest,
		clauseId: string
	) {
		return this.put(`clauses/${clauseId}`, clause);
	}

	async listClausesPaginated(
		params: ObjectWithAnyValues
	): Promise<BasePageResponse> {
		return super.listPaginated("clauses", params);
	}

	async changeFrequency(data: { clausesIds: string[]; frequency: number }) {
		return super.patch("clauses/change-frequency", data);
	}

	async changeTeams(data: { clausesIds: string[]; teamsIds: string[] }) {
		return super.patch("clauses/change-teams", data);
	}

	async close(id: string) {
		return super.patch(`clauses/${id}/close`, {});
	}
}
