import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import authReducer from "./features/auth/slice";
import categoryReducer from "./features/categories/slice";
import clauseReducer from "./features/clause/slice";
import covenantReducer from "./features/covenant/slice";
import searchReducer from "./features/search/slice";

const persistConfig = {
	key: "ccrcov",
	storage,
	whitelist: [
		"auth",
		"categories",
		"covenant",
		"clause",
		"search",
		"sessionToken"
	]
};

const appReducer = combineReducers({
	auth: authReducer,
	categories: categoryReducer,
	covenant: covenantReducer,
	clause: clauseReducer,
	search: searchReducer
});

const rootReducer = (state: any, action: any) => {
	if (action.type === "auth/setLogout") {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk]
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
