import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface ClauseCategory {
	clauseId: string;
	categoryId: string;
	categoryName: any;
}

export interface CategoryState {
	categories: ClauseCategory[];
}

const initialState = {
	categories: []
} as CategoryState;

export const categorySlice = createSlice({
	name: "categories",
	initialState,
	reducers: {
		setCategoriesCreated: (state, action: PayloadAction<ClauseCategory>) => {
			const { clauseId, categoryId, categoryName } = action.payload;

			const existClauseCategory = state.categories.findIndex(
				(category) => category.clauseId === clauseId
			);

			if (existClauseCategory !== -1) {
				console.log(existClauseCategory);
				state.categories[existClauseCategory] = {
					clauseId,
					categoryId,
					categoryName
				};
			} else {
				state.categories.push({ clauseId, categoryId, categoryName });
			}
		},
		clearCategoriedCreated: () => initialState
	}
});

export const { setCategoriesCreated, clearCategoriedCreated } =
	categorySlice.actions;
export default categorySlice.reducer;
