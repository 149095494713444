import {
	Button,
	Container,
	EmptyList as EmptyClausesList,
	Loading
} from "components";

import { SortingState } from "@tanstack/react-table";
import { IcoArrowLeft } from "assets/icons";
import ModalCancelConfirm from "components/ModalCancelConfirm";
import ToastContent from "components/ToastContent";
import { ErrorHelper } from "helpers";
import { Clause } from "models/covenant/Clause";
import { ClauseFilterTable } from "pages/Clauses/List/types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CovenantService } from "services/covenants";
import { clearCategoriedCreated } from "store/features/categories/slice";
import { setCovenantEdited } from "store/features/covenant/slice";
import { useFilteredClauses } from "../hooks/useFilteredClauses";
import { usePagination } from "../hooks/usePagination";
import CovenantAnalysisTable from "./CovenantAnalysisTable";
import StatusFilterHeaders from "./StatusFilterHeader";
import "./styles.scss";

export default function CovenantAnalysisPage() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const covenantService = new CovenantService();

	const [covenants, setCovenants] = useState<any>();
	const [isFetchingData, setIsFetchingData] = useState(false);
	const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
	const [confirmValidateModalOpen, setConfirmValidateModalOpen] =
		useState(false);
	const [statusFilter, setStatusFilter] = useState<string>("open");
	const [sorting, setSorting] = useState<SortingState>([]);
	const [filters, setFilters] = useState<ClauseFilterTable>({
		size: 10,
		page: 1
	});

	const getCovenants = useCallback(() => {
		setIsFetchingData(true);
		covenantService
			.retrieve(id as string)
			.then((response) => {
				setCovenants(response);
			})
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => setIsFetchingData(false));
	}, [id]);

	useEffect(() => {
		getCovenants();
	}, [getCovenants]);

	const filteredClauses = useFilteredClauses(
		covenants?.clauses || [],
		statusFilter,
		sorting
	);
	const { paginatedItems: pagedClauses, totalPages } = usePagination(
		filteredClauses,
		filters.page,
		filters.size
	);

	const openClausesCount =
		covenants?.clauses?.filter((clause: Clause) => !clause.validated)?.length ||
		0;
	const completedClausesCount =
		covenants?.clauses?.filter((clause: Clause) => clause.validated)?.length ||
		0;

	const handleSubmitCovenant = useCallback(() => {
		setIsLoadingAnalysis(true);
		covenantService
			.finalizeDraft(covenants?.covenantId)
			.then((resp) => {
				dispatch(setCovenantEdited(resp));
				dispatch(clearCategoriedCreated());
			})
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => {
				setIsLoadingAnalysis(false);
				navigate("/tasks");
			});
	}, [covenants, dispatch, navigate]);

	const onPageChange = useCallback((page: number, size: number) => {
		setFilters((prev) => ({ ...prev, page, size }));
	}, []);

	return (
		<Container className="covenant-analysis-page">
			<div className="covenant-analysis-heading">
				<Button
					kind="icon"
					styled="ghost"
					onClick={() => navigate("/covenants")}
				>
					<IcoArrowLeft size="32" color="#1D1D1D" />
				</Button>
				<h1 className="covenant-analysis-title">
					Validar Categorias - Contrato {covenants?.covenantNumber}
				</h1>

				<Button
					kind="default"
					styled="secondary"
					cssClass="cancel-covenant-analysis-button"
					onClick={() => navigate("/covenants")}
				>
					<span>Cancelar</span>
				</Button>
				<Button
					cssClass="complete-covenant-analysis-button"
					disabled={
						covenants?.clauses?.some((clause: Clause) => !clause.validated) ||
						!covenants
					}
					kind="default"
					isLoading={isLoadingAnalysis}
					styled="primary"
					onClick={() => setConfirmValidateModalOpen(true)}
				>
					<span>Finalizar</span>
				</Button>
			</div>
			<StatusFilterHeaders
				onFilterChange={setStatusFilter}
				openClausesCount={openClausesCount}
				completedClausesCount={completedClausesCount}
			/>
			<ModalCancelConfirm
				confirmLabel="Finalizar"
				changeButtonsPriority
				modalTitle="Deseja finalizar?"
				modalInfo='As cláusulas foram validadas. Antes de prosseguir, recomendamos revisar as abas "Em aberto" e "Aprovados".'
				isOpen={confirmValidateModalOpen}
				onClose={() => {
					setConfirmValidateModalOpen(false);
				}}
				onConfirm={() => handleSubmitCovenant()}
				toastSuccessTitle="Categorias validadas"
				toastSuccessMessage="As categorias foram validadas"
				kind="warning"
			/>
			{isFetchingData ? (
				<Loading type="primary" />
			) : statusFilter === "completed" && !completedClausesCount ? (
				<EmptyClausesList
					title="Nenhuma cláusula aprovada."
					message="Parece que não encontramos resultados correspondentes à sua pesquisa."
				/>
			) : statusFilter === "open" && !openClausesCount ? (
				<EmptyClausesList
					title="Todas as cláusulas foram aprovadas!"
					message="As cláusulas foram direcionadas para a aba de Aprovados."
				/>
			) : (
				<CovenantAnalysisTable
					covenantId={covenants?.covenantId}
					content={pagedClauses}
					currentPage={filters.page}
					onPageChange={onPageChange}
					pageSize={filters.size}
					totalPages={totalPages}
					reloadData={getCovenants}
					sorting={sorting}
					setSorting={setSorting}
					statusFilter={statusFilter}
				/>
			)}
		</Container>
	);
}
