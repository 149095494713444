import { HTMLAttributes } from "react";
import "./styles.scss";

type ColoredTagProps = HTMLAttributes<HTMLDivElement> & {
	text: string | number;
	type: "default" | "success" | "alert" | "error" | "draft" | "empty";
};

export default function ColoredTag({
	text,
	type,
	className,
	...args
}: ColoredTagProps) {
	return (
		<div {...args} className={`colored-tag--${type} ${className ?? ""}`}>
			<p>{text}</p>
		</div>
	);
}
