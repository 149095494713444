import { SortingState } from "@tanstack/react-table";
import { Clause } from "models/covenant/Clause";
import { useMemo } from "react";

export const useFilteredClauses = (
	clauses: Clause[],
	statusFilter: string,
	sorting: SortingState
) => {
	return useMemo(() => {
		const getValue = (item: Clause, covenantId: string) => {
			switch (covenantId) {
				case "suggestedCategory":
					return item.suggestedCategory?.name ?? "";
				case "teams":
					return item.teams[0].name ?? "";
				case "assertiveness":
					return item.assertiveness ?? "";
				default:
					return item[covenantId as keyof typeof item] ?? "";
			}
		};

		const filtered = clauses.filter((clause) =>
			statusFilter === "open" ? !clause.validated : clause.validated
		);

		if (!sorting.length) {
			return filtered;
		}

		const [{ id, desc }] = sorting;

		return [...filtered].sort((a, b) => {
			const aValue = getValue(a, id)?.toString().toLowerCase() ?? "";
			const bValue = getValue(b, id)?.toString().toLowerCase() ?? "";

			if (aValue < bValue) return desc ? 1 : -1;
			if (aValue > bValue) return desc ? -1 : 1;
			return 0;
		});
	}, [clauses, statusFilter, sorting]);
};
